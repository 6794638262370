<script setup lang="ts">
import { vElementVisibility, vOnClickOutside } from '@vueuse/components';
import { type PropType, ref } from 'vue';

import { useDropdown } from '../composables/useDropdown';
import { useModalOverflow } from '../composables/useModalOverflow';
import type { JamBaseSelectOption } from './JamBaseSelect.vue';

export type JamBaseMultiSelectOption = JamBaseSelectOption & {
    conflicts?: string[];
    conflictValue?: string;
    disableAllOtherOptions?: boolean;
};

const props = defineProps({
    disabled: {
        default: false,
        type: Boolean,
    },
    error: {
        default: null,
        type: String,
    },
    highlighted: {
        default: false,
        type: Boolean,
    },
    icon: {
        default: null,
        type: String,
    },
    info: {
        default: null,
        type: String,
    },
    infoLabel: {
        default: 'Hinweis',
        type: String,
    },
    label: {
        default: null,
        type: String,
    },
    modelValue: {
        default: null,
        type: Array as PropType<(string | number | boolean)[]>,
    },
    options: {
        default: null,
        type: Array as () => JamBaseMultiSelectOption[],
    },
    placeholder: {
        default: 'Bitte auswählen',
        type: String,
    },
    showAllSelected: {
        default: false,
        type: Boolean,
    },
});

const emit = defineEmits(['update:modelValue', 'change']);

const id = useId();
const opened = ref<boolean>(false);
const highlightedOption = ref<string | number | boolean | null>();
const localValues = ref<{ [key: string | number]: boolean }>({});
const pillVisibility = ref<{ [key: string | number]: boolean }>({});

const targetElement = ref<HTMLElement | null>(null);
const dropdownElement = ref<HTMLElement | null>(null);
const { cancelDropdownPositionWatcher, initDropdownPositionWatcher, openTop } =
    useDropdown(targetElement, dropdownElement);

const { setModalOverflowVisible } = useModalOverflow();

const updateLocalValues = () => {
    props.options?.forEach((item) => {
        localValues.value[item.value as string] = props.modelValue?.includes(
            item.value + '',
        );
    });
};

const isOptionDisabled = (selectOption: JamBaseMultiSelectOption) => {
    const exclusiveSelectOption = props.options.find(
        (option) =>
            option.disableAllOtherOptions &&
            localValues.value[option.value as string],
    );

    if (exclusiveSelectOption) {
        return selectOption.value !== exclusiveSelectOption.value;
    }

    return props.options.some((option) => {
        return (
            option.conflicts?.includes(selectOption.conflictValue as string) &&
            localValues.value[option.value as string]
        );
    });
};

onMounted(() => {
    if (props.modelValue) {
        updateLocalValues();
    }
});

const keyboardNavigation = (event: KeyboardEvent) => {
    if (!props.options) return;

    if (
        event.key === 'ArrowUp' ||
        event.key === 'ArrowDown' ||
        event.key === 'Enter' ||
        event.code === 'Space'
    ) {
        event.preventDefault();

        let index = props.options.findIndex(
            (option) => option.value === highlightedOption.value,
        );

        const getNextIndex = (
            currentIndex: number,
            direction: 'up' | 'down',
        ) => {
            let newIndex = currentIndex;
            do {
                if (direction === 'down') {
                    newIndex = (newIndex + 1) % props.options.length;
                } else {
                    newIndex =
                        (newIndex - 1 + props.options.length) %
                        props.options.length;
                }
            } while (
                isOptionDisabled(props.options[newIndex]) &&
                newIndex !== currentIndex
            );

            return newIndex;
        };

        if (event.key === 'ArrowDown') {
            index = getNextIndex(index, 'down');
        }

        if (event.key === 'ArrowUp') {
            index = getNextIndex(index, 'up');
        }

        highlightedOption.value = props.options[index]?.value;

        if (
            (event.code === 'Space' || event.key === 'Enter') &&
            highlightedOption.value
        ) {
            const highlightedOptionObject = props.options.find(
                (option) => option.value === highlightedOption.value,
            );

            if (
                highlightedOptionObject &&
                !isOptionDisabled(highlightedOptionObject)
            ) {
                localValues.value[highlightedOption.value as string] =
                    !localValues.value[highlightedOption.value as string];
            }
        }
    }

    // Close on Escape or Tab
    if (event.key === 'Escape' || event.key === 'Tab') {
        close();
    }
};

const open = () => {
    if (!props.options) return;

    opened.value = true;

    setModalOverflowVisible(true);

    document.addEventListener('keydown', keyboardNavigation);
    initDropdownPositionWatcher();
};

const close = () => {
    opened.value = false;

    setModalOverflowVisible(false);

    document.removeEventListener('keydown', keyboardNavigation);
    cancelDropdownPositionWatcher();
};

const toggle = () => {
    if (opened.value) {
        close();
    } else {
        open();
    }
};

watch(
    () => props.modelValue,
    () => {
        updateLocalValues();
    },
);

watch(
    () => props.options,
    () => {
        if (!props.modelValue?.length) {
            return;
        }
        updateLocalValues();
    },
);

watch(
    localValues,
    () => {
        if (!props.options) return;

        const newValues: (string | number | boolean)[] = [];
        for (const key in localValues.value) {
            if (localValues.value[key]) {
                newValues.push(key + '');
            } else {
                delete pillVisibility.value[key];
            }
        }

        const hasChange = () => {
            for (const val of newValues) {
                if (!props.modelValue?.includes(val)) {
                    return true;
                }
            }

            for (const val of props.modelValue) {
                if (!newValues.includes(val)) {
                    return true;
                }
            }

            return false;
        };

        if (!hasChange()) {
            return;
        }

        emit('update:modelValue', newValues);
        emit('change');
    },
    { deep: true },
);
</script>

<template>
    <div role="listbox" :data-invalid-field="error || null">
        <div
            v-if="label || info"
            class="mb-2 flex items-end justify-between gap-4"
        >
            <label :for="id" class="max-w-full">
                <JamBaseText
                    v-if="label"
                    class="text-gray-600"
                    variant="small"
                    :title="label"
                    :is-label="true"
                >
                    {{ label }}
                </JamBaseText>
            </label>
            <div class="shrink-0">
                <JamBaseTooltip v-if="info || $slots.info">
                    <template #info><slot name="info" />{{ info }}</template>
                    <JamBaseText
                        class="cursor-help border-b border-dashed border-gray-600 text-gray-600 hover:text-gray-900"
                        variant="small"
                    >
                        {{ infoLabel }}
                    </JamBaseText>
                </JamBaseTooltip>
            </div>
        </div>
        <div
            v-on-click-outside="close"
            class="relative w-full"
            :class="{
                'cursor-not-allowed opacity-40': disabled,
            }"
        >
            <button
                :id="id"
                ref="targetElement"
                class="w-full text-start"
                type="button"
                :disabled="disabled"
                @click="toggle"
            >
                <span
                    :id="id"
                    class="flex w-full items-center gap-2 rounded border bg-white py-3 pe-8"
                    :class="{
                        'border-gray-300': !opened,
                        'border-gray-600': opened,
                        '!ps-8': icon,
                        '!ps-3': !icon,
                        'border-red-700': error,
                        'border-yellow-600': highlighted,
                    }"
                >
                    <template
                        v-if="Object.values(localValues).some((value) => value)"
                    >
                        <span
                            class="flex gap-2"
                            :class="[
                                showAllSelected
                                    ? 'flex-wrap'
                                    : 'overflow-hidden',
                            ]"
                        >
                            <JamBasePill
                                v-for="selectedOption in options?.filter(
                                    (option: JamBaseMultiSelectOption) =>
                                        localValues[option.value as string],
                                )"
                                :key="selectedOption.value as string"
                                v-element-visibility="[
                                    (state: boolean) => {
                                        // Set pill visibility state for each selected option on visibility change
                                        if (
                                            localValues[
                                                selectedOption.value as string
                                            ]
                                        ) {
                                            pillVisibility[
                                                selectedOption.value as string
                                            ] = state;
                                        }
                                    },
                                    { threshold: 1 },
                                ]"
                                close-button
                                class="max-w-full"
                                :class="{
                                    'pointer-events-none !opacity-0':
                                        !pillVisibility[
                                            selectedOption.value as string
                                        ],
                                }"
                                :disabled="disabled"
                                @click.stop="
                                    localValues[
                                        selectedOption.value as string
                                    ] = false
                                "
                            >
                                <span
                                    class="block max-w-full truncate whitespace-nowrap"
                                >
                                    {{ selectedOption.label }}
                                </span>
                            </JamBasePill>
                        </span>
                        <JamBasePill
                            v-if="
                                Object.values(pillVisibility)?.filter(
                                    (value) => !value,
                                ).length !== 0
                            "
                        >
                            +{{
                                Object.values(pillVisibility)?.filter(
                                    (value) => !value,
                                ).length
                            }}
                        </JamBasePill>
                    </template>
                    <span v-else class="truncate py-2 ps-1">
                        {{ placeholder }}
                    </span>
                </span>
            </button>

            <ul
                v-if="opened"
                ref="dropdownElement"
                class="absolute inset-y-0 left-0 z-10 flex h-fit max-h-[400px] w-full flex-col overflow-auto rounded border border-gray-300 bg-white transition-all"
                :class="
                    openTop ? 'bottom-full -translate-y-full' : 'top-full mt-1'
                "
                role="list"
            >
                <template v-for="(item, index) in options" :key="item.value">
                    <span
                        v-if="
                            item.category &&
                            options.findIndex(
                                (i, idx) =>
                                    i.category === item.category && idx < index,
                            ) === -1
                        "
                        :key="`${item.value}-category`"
                        class="mx-3 block border-b border-gray-100 py-3"
                    >
                        <JamBasePill
                            size="small"
                            class="w-full !justify-center"
                        >
                            {{ item.category }}
                        </JamBasePill>
                    </span>
                    <li
                        role="listitem"
                        class="flex w-full items-center justify-between gap-4 transition-colors"
                        :class="{
                            'bg-gray-50': highlightedOption === item.value,
                        }"
                        @mouseover="
                            !isOptionDisabled(item) &&
                                (highlightedOption = item.value)
                        "
                        @mouseleave="highlightedOption = null"
                    >
                        <JamBaseCheckbox
                            v-model="localValues[item.value as string]"
                            :value="item.value"
                            :label="item.label"
                            class="w-full p-3"
                            :disabled="isOptionDisabled(item)"
                        />
                        <JamBasePill
                            v-if="item.badgePill"
                            variant="blue"
                            size="small"
                            class="me-3"
                        >
                            {{ item.badgePill }}
                        </JamBasePill>
                    </li>
                </template>
            </ul>
            <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pe-4 text-gray-700"
            >
                <JamBaseIcon
                    icon-name="arrow-down"
                    size="medium"
                    class="transition-transform"
                    :class="[opened && 'rotate-180']"
                />
            </div>
            <div
                class="pointer-events-none absolute inset-y-0 left-0 flex items-center pe-4 ps-4 text-gray-700"
            >
                <JamBaseIcon v-if="icon" :icon-name="icon" stroke="thick" />
            </div>
        </div>
        <JamBaseText v-if="error" variant="small" class="mt-2 text-red-700">
            {{ error }}
        </JamBaseText>
    </div>
</template>
